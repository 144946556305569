import Navbar from "../components/Navbar.js";
import "../App.css";
import { useEffect } from "react";
import i18next from "../i18n";

function DeleteAccount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />

      <div className="termsHeaderTitle">
        <h1>{i18next.t("del_Header_Title")}</h1>
      </div>

      <div className="containers">
        <ol>
         <li className="listTitle">
                1. {i18next.t("del_Headers_1")}
                <ul>
                  <li>· {i18next.t("del_text_1")}</li>
                </ul>             
          </li>

          <li className="listTitle">
                2. {i18next.t("del_Headers_2")}
                <ul>
                  <li>· {i18next.t("del_text_2")}</li>
                  <li>· {i18next.t("del_text_3")}</li>
                </ul>             
          </li>
         
          <li className="listTitle">
                3. {i18next.t("del_text_4")}
          </li>

          <li className="listTitle">
                4. {i18next.t("del_text_5")}
          </li>

          <li className="listTitle">
                5. {i18next.t("del_Headers_3")}
                <ul>
                  <li>· {i18next.t("del_text_6")}</li>
                  <li>· {i18next.t("del_text_7")}</li>
                </ul>             
          </li>

          <li className="listTitle">
                6. {i18next.t("del_text_8")}
          </li>

          <li className="listTitle">
                7. {i18next.t("del_text_9")}
          </li>

         <li className="listTitle">
                8. {i18next.t("del_text_10")}
          </li>

         <li className="listTitle">
                9. {i18next.t("del_text_11")}
          </li>

          <li className="listTitle">
                10. {i18next.t("del_text_12")}
          </li>

          <li className="listTitle">
                11. {i18next.t("del_text_13")}
          </li>

          <li className="listTitle">
                12. {i18next.t("del_text_14")}
          </li>


          <li className="listTitle">
                13. {i18next.t("del_text_15")}
          </li>

          <li className="listTitle">
                14. {i18next.t("del_text_16")}
          </li>

         <li className="listTitle">
                15. {i18next.t("del_text_17")} «<span><a href="mailto:support@qwique.com " style={{
                                textDecoration: "none", fontWeight: "700",
                                color: "#FF7011",
                            }}>support@qwique.com</a>».</span>
          </li>

         <li className="listTitle">
                16. {i18next.t("del_text_18")}
          </li>

          <li className="listTitle">
                17. {i18next.t("del_text_19")}
          </li>

          <li className="listTitle">
                18. {i18next.t("del_text_20")}
          </li>

          <li className="listTitle">
                19. {i18next.t("del_text_21")}
          </li>

        </ol>

        <p className="termsDescription">{i18next.t("del_Description")} </p>
      </div>
    </div>

    );
}

export default DeleteAccount;
