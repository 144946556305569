import { useRef, useEffect } from "react";
import { register } from "swiper/element/bundle";
import Mainbody from "./Landingpage";

register();

export default function LandingPageSwiper() {
  const swiperElRef = useRef(null);

  useEffect(() => {
    // listen for Swiper events using addEventListener
    // swiperElRef.current.addEventListener("progress", (e) => {
    //   const [swiper, progress] = e.detail;
    //   console.log(progress);
    // });

    swiperElRef.current.addEventListener("slidechange", (e) => {
      console.log("slide changed");
    });
  }, []);
  return (
    <>
      <swiper-container
        ref={swiperElRef}
        slides-per-view="1"
        autoplay="true"
        autoplay-delay="4500"
        // loop="true"
        // loop-prevent-sliding="true"
        // looped-slides="0"
      >
        <swiper-slide data-swiper-slide-index="1" aria-label="1 / 3">
          <Mainbody
            header_title_one={"header_title_one"}
            header_title_two={"header_title_two"}
            header_title_three={"header_title_three"}
            header_title_four={"header_title_four"}
            header_description={"header_description"}
          />
        </swiper-slide>
        <swiper-slide data-swiper-slide-index="2" aria-label="2 / 3">
          <Mainbody
            header_title_one={"sm_header_title_one"}
            header_title_two={"sm_header_title_two"}
            header_title_three={"sm_header_title_three"}
            header_title_four={"sm_header_title_four"}
            header_description={"sm_header_description"}
          />
        </swiper-slide>
        <swiper-slide data-swiper-slide-index="3" aria-label="3 / 3">
          <Mainbody
            header_title_one={"ds_header_title_one"}
            header_title_two={"ds_header_title_two"}
            header_title_three={"ds_header_title_three"}
            header_title_four={"ds_header_title_four"}
            header_description={"ds_header_description"}
          />
        </swiper-slide>
      </swiper-container>
    </>
  );
}
